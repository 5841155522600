<template>
  <v-edit-dialog large @open="open" @save="save">
    <v-btn color="#333" elevation="1" outlined small>
      {{ customer.score }}
    </v-btn>
    <template v-slot:input>
      <v-text-field v-model="metaValue" :loading="loading" label="Cate puncte vrei sa adaugi clientului?"/>
    </template>
  </v-edit-dialog>
</template>

<script>
export default {
  name: 'ClientAddExtraPoints',
  data () {
    return {
      loading: false,
      metaValue: ''
    }
  },
  props: {
    customer: {
      required: true
    }
  },
  methods: {
    open () {
      this.metaValue = ''
    },
    save () {

      this.loading = true
      this.$http.patch(`/clients/resellers/${this.customer.id}/add-points`, {
        value: this.metaValue
      })
        .then(() => {
          this.$emit('changed')
          this.$vs.notify({
            title: 'Succes!',
            text: 'Agentul a fost actualizat cu succes!!',
            color: 'success'
          })
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare!',
            text: 'A aparut o eroare la adaugarea punctelor. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
