<template>
  <div>
    <v-edit-dialog large @open="open" @save="save">
      <v-chip color="#60a629" dark>{{ client.discount }}</v-chip>
      <template v-slot:input>

        <div class="mt-4 title">
          <label for="discountValue">Seteaza discountul</label>
        </div>
        <div class="">
          <select v-if="optionsLoaded" id="discountValue" v-model="discountValue" class="defaultSelectBox w-full border border-solid border-grey rounded px-1">
            <option v-for="(option, index) in options" :key="index" :value="option">{{ option }}</option>
          </select>
        </div>
      </template>
    </v-edit-dialog>
  </div>
</template>

<script>
import ClientAgent from '@/views/Clients/components/ClientAgent'

export default {
  name: 'ClientDiscountSelect',
  components: {ClientAgent},
  data () {
    return {
      discountValue: ''
    }
  },
  computed: {
    options () {
      return this.$store.getters.clientDiscountList
    },
    optionsLoaded () {
      return this.$store.getters.clientDiscountListIsLoaded
    }
  },
  props: {
    client: {
      required: true
    }
  },
  methods: {
    open () {
      this.discountValue = this.client.discount
    },
    save () {
      this.$store.dispatch('startLoading')
      this.$http.patch(`/clients/${this.client.id}/change-discount`, {discount: this.discountValue})
        .then(() => {
          this.$emit('changed')
          this.$vs.notify({
            title: 'Succes!',
            text: 'Discountul a fost actualizat cu succes!!',
            color: 'success'
          })
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare!',
            text: 'A aparut o eroare la salvarea discountului. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          })
        })
        .finally(() => this.$store.dispatch('stopLoading'))

    }
  },
  mounted () {
    if (!this.$store.getters.clientDiscountListIsLoaded) {
      this.$store.dispatch('loadClientDiscountList')
    }
  }
}
</script>


<style scoped>

select.defaultSelectBox {
  /*noinspection CssInvalidPropertyValue*/
  appearance: menulist;
  cursor: default;
  border: 1px solid;
}
</style>
