<template>
  <span>
    <v-edit-dialog large @open="open" @save="save" v-if="$store.getters['auth/currentUser'].is_admin">
      <client-agent :agent="agent" />
      <template v-slot:input>
        <div class="mt-4 title">
          <label for="activeAgentId">Modifica Agentul</label>
        </div>
        <div class="mt-4">
          <select id="activeAgentId" v-model="activeAgentId" class="defaultSelectBox">
            <template v-if="$store.getters['auth/currentUser'].franchisee_id">
              <option v-for="agentOption in franchiseeAgents" :key="agentOption.id" :value="agentOption.id">
                {{ agentOption.full_name }}
              </option>
            </template>
            <template v-else>
              <option v-for="agentOption in agents" :key="agentOption.id" :value="agentOption.id">
                {{ agentOption.full_name }}
              </option>
            </template>
          </select>
        </div>
      </template>
    </v-edit-dialog>
    <span v-else>
      {{ agent.full_name }}
    </span>
  </span>
</template>

<script>
import ClientAgent from '@/views/Clients/components/ClientAgent'
import axios from '@/axios'
export default {
  name: 'ClientAgentReplacer',
  components: { ClientAgent },
  data() {
    return {
      activeAgentId: '',
      franchiseeAgents: [],
    }
  },
  props: {
    agent: {
      required: true
    },
    client: {
      required: true
    }
  },
  computed: {
    agents() {
      return this.$store.getters['agents/data']
    }
  },
  methods: {
    open() {
      this.activeAgentId = this.agent.id
    },
    save() {
      this.$http.patch(`/clients/${this.client.id}/change-agent`, { agent_id: this.activeAgentId })
        .then(() => {
          this.$emit('changed')
          this.$vs.notify({
            title: 'Succes!',
            text: 'Agentul a fost actualizat cu succes!!',
            color: 'success'
          })
          this.$store.dispatch('loadNavigation')
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare!',
            text: 'A aparut o eroare la salvarea agentului. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          })
        })
    },
    loadItems() {
      const franchisee_id = this.$store.getters['auth/currentUser'].franchisee_id
      if (franchisee_id) {
        axios
          .get('/users/getUsersByFranchisee')
          .then(response => {
            const responseData = response.data
            if (responseData) {
              this.franchiseeAgents = responseData
            }
          })
      }
    },
  },
  created() {
    this.loadItems()
  },
  mounted() {
    if (!this.$store.getters['agents/isLoaded']) {
      this.$store.dispatch('agents/loadItems')
    }
  }
}
</script>

<style scoped>
select.defaultSelectBox {
  /*noinspection CssInvalidPropertyValue*/
  appearance: menulist;
  cursor: default;
  border: 1px solid;
}
</style>
