<template>
<div>
  <v-chip dark>
  {{agentName}}
  </v-chip>
</div>
</template>

<script>
export default {
  name: 'ClientAgent',
  computed:{
    agentName () {
      if (this.agent !== null) {
        return this.agent.full_name
      } else {
        return '-'
      }
    }
  },
  props: {
    agent: {required: true}
  }
}
</script>
