<template>
  <div>
    <v-edit-dialog large @open="open" @save="save">
      <template v-if="client.interest_lists.length">
        <ul class="pl-0">
          <li v-for="interest in client.interest_lists" :key="interest.id">
            <span class="text-xs">&#9862; {{ interest.name }}</span>
          </li>
        </ul>

      </template>

      <v-btn color="primary" class="primary-button" x-small>
        <v-icon left>
          mdi-plus
        </v-icon>
        Adauga
      </v-btn>

      <template v-slot:input>
        <client-interest-list-select v-model="interestsIds"/>
      </template>
    </v-edit-dialog>
  </div>
</template>

<script>
import ClientAgent from '@/views/Clients/components/ClientAgent'
import ClientInterestListSelect from '@/components/autocompletes/ClientInterestListSelect'

export default {
  name: 'ClientInterestSelect',
  components: {
    ClientInterestListSelect,
    ClientAgent
  },
  data () {
    return {
      interestsIds: []
    }
  },
  computed: {
    options () {
      return this.$store.getters.clientDiscountList
    },
    optionsLoaded () {
      return this.$store.getters.clientDiscountListIsLoaded
    }
  },
  props: {
    client: {
      required: true
    }
  },
  methods: {
    open () {
      this.interestsIds = this.client.interest_lists.map(function (item) {
        return item.id
      })
    },
    save () {
      this.$store.dispatch('startLoading')
      this.$http.patch(`/clients/${this.client.id}/interest-lists`, {interests: this.interestsIds})
        .then(() => {
          this.$emit('updated')
          this.$vs.notify({
            title: 'Succes!',
            text: 'Lista de interese a fost actualizata cu succes!!',
            color: 'success'
          })
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare!',
            text: 'A aparut o eroare la salvare. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          })
        })
        .finally(() => this.$store.dispatch('stopLoading'))

    }
  },
  mounted () {
    if (!this.$store.getters.clientDiscountListIsLoaded) {
      this.$store.dispatch('loadClientDiscountList')
    }
  }
}
</script>
